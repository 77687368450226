/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Form as BSForm,
  Button,
  Collapse,
  InputGroup,
  Pagination,
} from 'react-bootstrap';
import { FaSearch, FaSlidersH, FaTimes } from 'react-icons/fa';
import { Element } from 'react-scroll';
import DefaultLayout from '@/layouts/default';
import Masthead from '@/components/layout/masthead';
import { formatDataAndExtractTags } from '@/utilities/general';
import usePagination from '@/hooks/use-pagination';
import useFilterForm from '@/hooks/use-filter-form';
import SEO from '@/components/layout/seo';
import Showdown from 'showdown';

const Page = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allGlossaryXlsxSheet1 {
        nodes {
          id
          term
          definition
        }
      }
    }
  `);

  const dataset = formatDataAndExtractTags(
    data.allGlossaryXlsxSheet1.nodes,
    ['term'],
    ['term', 'definition']
  );

  const [allItems] = useState(dataset.data);
  const [filteredItems, setFilteredItems] = useState([]);

  const [
    register,
    resetForm,
    isDirty,
    submit,
    menuOpen,
    node,
    changed,
    setValue,
  ] = useFilterForm(setFilteredItems, allItems, 'sort-term');

  const [paginationItems, offset, limit] = usePagination(filteredItems.length);

  useEffect(() => {
    changed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, setValue]);

  const md = new Showdown.Converter();

  return (
    <DefaultLayout pageInfo={location}>
      <SEO title="Glossary" keywords={[]} />

      <Masthead location={location}>
        <div className="glossary-hero">
          <Container className="my-4">
            <Row>
              <Col lg={7}>
                <p>
                  <small>
                    <Link to="/tools/">Explore Tools</Link> | Glossary
                  </small>
                </p>
                <h2>Glossary</h2>
                <p>
                  Below are definitions for terms that are used in the
                  Partnership for Pre-K Improvement’s Toolkit. While these terms
                  may be used and interpreted in multiple ways in the early
                  learning field, the definitions below are specific to how
                  these terms are used within the context of this toolkit.
                </p>
              </Col>
              <Col
                className="text-right position-relative top-25 d-none d-lg-flex "
                lg={{ span: 3, offset: 2 }}
              />
            </Row>
          </Container>
        </div>
      </Masthead>

      <Element name="scrollTop" />
      <Container className="mt-5">
        <Row>
          <Col lg={3} className="pr-5  mt-3" id="" ref={node}>
            <form onSubmit={submit}>
              <BSForm.Group controlId="basic-filter">
                <BSForm.Label>
                  <strong>Search Glossary</strong>
                </BSForm.Label>
                <InputGroup>
                  <BSForm.Control
                    type="text"
                    ref={register}
                    onBlur={changed}
                    name="search"
                  />
                  {isDirty && (
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <Button href="#" onClick={resetForm}>
                          <FaTimes />
                          <span className="sr-only">Clear</span>
                        </Button>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  )}
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <Button href="#" onClick={submit}>
                        <FaSearch />
                        <span className="sr-only">Search</span>
                      </Button>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </BSForm.Group>
            </form>
          </Col>
          <Col>
            {filteredItems &&
              filteredItems.slice(offset, limit).map((item) => (
                <Row key={item.term}>
                  <Col lg={9}>
                    <h5 className="mt-3 mb-0">{item.term}</h5>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: md.makeHtml(item.definition),
                      }}
                    ></div>
                  </Col>
                </Row>
              ))}
            {paginationItems.length > 0 && (
              <div className="py-5 d-flex align-items-center">
                <span>Page</span>
                <Pagination className="mb-0 px-2">{paginationItems}</Pagination>
              </div>
            )}
            {paginationItems.length === 0 && (
              <p>
                <em>
                  Your filter or search returned no results. Please try again.{' '}
                  {isDirty && (
                    <Button
                      variant="link"
                      onClick={(e) => {
                        resetForm(e);
                      }}
                    >
                      Clear Filters
                    </Button>
                  )}
                </em>
              </p>
            )}
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  );
};

Page.propTypes = {
  location: PropTypes.shape(),
};

Page.defaultProps = {
  location: null,
};

export default Page;
