import {
  useState, useEffect, useRef,
} from 'react';
import { useForm } from 'react-hook-form/dist/index.ie11';
import arraySort from 'array-sort';
import { filterData } from '@/utilities/general';

export default (setFilteredItems, allItems, defaultSort) => {
  const {
    register, getValues, reset, formState, setValue,
  } = useForm();
  const { isDirty } = formState;

  const [currentSort, setCurrentSort] = useState(defaultSort);

  const changed = (e, sortField = null) => {
    const form = getValues();
    const filtered = filterData(allItems, form);
    let sort = sortField;
    if (!sort) {
      sort = currentSort;
    }
    const dir = { reverse: false };
    if (sort === 'sort-index') {
      dir.reverse = true;
    }
    const items = arraySort(filtered, sort, dir);
    setCurrentSort(sort);
    setFilteredItems(items);
  };

  const updateSortField = (field) => {
    changed(null, field);
  };

  // handle the display of the menu on mobile
  const [menuOpen, setMenuOpen] = useState(false);
  const node = useRef();
  const handleClick = (e) => {
    if (node.current.contains(e.target) || e.target.id === 'filterButton') {
      return;
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const submit = (e) => {
    e.preventDefault();
    changed();
  };

  const resetForm = (e) => {
    e.preventDefault();
    reset();
    changed();
  };

  return [
    register,
    resetForm,
    isDirty,
    submit,
    menuOpen,
    node,
    changed,
    setMenuOpen,
    updateSortField,
    setValue,
    getValues,
  ];
};
